<template>
  <CentralizeLayout>
    <div class="bg-1"></div>
    <div class="bg-2"></div>
    <b-col md="4">
      <div class="text-center mb-4">
        <logo name="logo" />
      </div>
      <b-alert
        class="mt-3"
        :show="hasAuthenticationStatus"
        :variant="'danger'"
        >{{ $t(`errorResetPass.${errorCode}`) }}</b-alert
      >
      <b-card class="p-2 auth-card-border">
        <h5 class="mb-2">{{ $t("forgotPassword") }}</h5>
        <p class="mb-4 description">{{ $t("enterEmailAddress") }}</p>
        <b-form class="mt-3" novalidate @submit.prevent="onSubmit">
          <b-form-group :label="$t('email')">
            <b-input-group>
              <b-form-input
                v-model.trim.lazy="$v.form.email.$model"
                type="email"
                required
                :state="validateState('email')"
                :disabled="isLoading"
                @blur="$v.form.email.$touch()"
              />
              <b-form-invalid-feedback>{{
                $t("emailErrMsg")
              }}</b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-button
            class="mt-4"
            block
            :disabled="isLoading"
            type="submit"
            variant="primary"
          >
            <b-icon-arrow-clockwise v-show="isLoading" animation="spin" />
            {{ $t("sendResetPassLink") }}
          </b-button>
        </b-form>
        <div class="mt-2 d-flex justify-content-center">
          <b-button
            variant="link"
            :to="{ name: 'Login', query: { lang: this.$route.query.lang } }"
          >
            {{ $t("returnLogin") }}
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col lg="4" class="text-footer">
      <div>
        <span class="mr-2">{{ $t("contact") }}</span>
        <span class="mr-2">{{ $t("legal") }}</span>
        <span>{{ $t("privacy") }}</span>
      </div>

      <div class="ml-auto">
        <b-dropdown class="language-dropdown" dropup no-caret right>
          <template #button-content>
            <div class="d-flex flex-row align-items-center">
              <icon class="mr-2" name="language" />
              <span v-if="$root.$i18n.locale === 'en'" style="font-size: 12px;"
                >English</span
              >
              <span v-if="$root.$i18n.locale === 'id'" style="font-size: 12px;"
                >Indonesia</span
              >
              <b-icon
                class="caret ml-1"
                icon="caret-up-fill"
                font-scale="0.85"
              />
            </div>
          </template>
          <b-dropdown-item @click="setLanguageEn">English</b-dropdown-item>
          <b-dropdown-item @click="setLanguageId"
            >Bahasa Indonesia</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </b-col>
  </CentralizeLayout>
</template>

<i18n>
{
  "en": {
    "forgotPassword": "Forgot password?",
    "email": "Email",
    "emailErrMsg": "Choose a valid email address.",
    "enterEmailAddress": "Enter the email address associated with your account and we'll send you a link to reset your password.",
    "returnLogin": "Return to log in",
    "sendResetPassLink": "Send reset password code",
    "errorResetPass": {
      "UsernameExistsException": "An account with the given email already exists.",
      "unspecific": "Email address is not linked to Survein."
    },
    "agreeTermAndCondition": "I’m agree to Survein’s term & condition",
    "contact": "Contact",
    "legal": "Legal",
    "privacy": "Privacy & terms",
    "languageSaved": "Language is saved"
  },
  "id": {
    "forgotPassword": "Lupa kata sandi?",
    "email": "Email",
    "emailErrMsg": "Pilih alamat email yang valid.",
    "enterEmailAddress": "Masukkan alamat email yang terkait dengan akun Anda dan kami akan mengirimkan tautan untuk mengatur ulang kata sandi Anda.",
    "returnLogin": "Kembali untuk masuk",
    "sendResetPassLink": "Kirim kode atur ulang kata sandi",
    "errorResetPass": {
      "UsernameExistsException": "Akun dengan email yang diberikan sudah ada.",
      "unspecific": "Email tidak terdaftar di Survein."
    },
    "agreeTermAndCondition": "Saya setuju dengan syarat & ketentuan Survein",
    "contact": "Kontak",
    "legal": "Hukum",
    "privacy": "Privasi & persyaratan",
    "languageSaved": "Bahasa disimpan"
  }
}
</i18n>

<script>
import { required, email } from "vuelidate/lib/validators";
import CentralizeLayout from "@/layouts/CentralizeLayout";
import { mapGetters, mapActions } from "vuex";

const errorCodes = ["UsernameExistsException"];
const fallbackErrorCode = "unspecific";

export default {
  components: {
    CentralizeLayout
  },
  props: {
    lang: {
      type: String,
      default: "en"
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        code: "",
        password: "",
        reenterPassword: ""
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationStatus", "authenticationStatus"]),
    errorCode() {
      return errorCodes.includes(this.authenticationStatus.code)
        ? this.authenticationStatus.code
        : fallbackErrorCode;
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  mounted() {
    this.clearAuthenticationStatus();

    if (this.lang === "en") {
      this.$root.$i18n.locale = "en";
    } else {
      this.$root.$i18n.locale = "id";
    }
  },
  methods: {
    ...mapActions("auth", ["clearAuthenticationStatus", "passwordReset"]),
    ...mapActions("profile", ["setLanguage"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && $error ? false : null;
    },
    setLanguageEn() {
      this.$router.replace({ query: { lang: "en" } });
    },
    setLanguageId() {
      this.$router.replace({ query: { lang: "id" } });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      await this.passwordReset({
        username: this.form.email
      });
      if (!this.hasAuthenticationStatus) {
        this.$router.push({
          name: "ConfirmResetPassword",
          params: { email: this.form.email }
        });
      }
      this.isLoading = false;
    }
  },
  watch: {
    $route() {
      if (this.$route.query.lang === "id") {
        this.$root.$i18n.locale = "id";
      } else {
        this.$root.$i18n.locale = "en";
      }
    }
  }
};
</script>

<style scoped>
.description {
  font-size: 14px;
  font-weight: 400;
  color: #606977;
}
</style>
